import fetch from "auth/FetchInterceptor";

const BannerService = {};

BannerService.Create = function (data) {
  return fetch({
    url: "/Banner/Create",
    method: "post",
    data,
  });
};

BannerService.Update = function (data) {
  return fetch({
    url: "/Banner/Update",
    method: "post",
    data,
  });
};
BannerService.Activate = function (params) {
  return fetch({
    url: "/Banner/Activate",
    method: "post",
    params,
  });
};
BannerService.GetAll = function () {
  return fetch({
    url: "/Banner/GetAll",
    method: "get",
  });
};
BannerService.GetById = function (params) {
  return fetch({
    url: "/Banner/GetById",
    method: "get",
    params,
  });
};
BannerService.GetAllPages = function () {
  return fetch({
    url: "/Banner/GetAllPages",
    method: "get",
  });
};

export default BannerService;
