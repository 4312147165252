import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ContactUsService from "services/ContactUsFormService";
import { message } from "antd";

export const initialState = {
  ssrData: [],
  loadingSSR: false,
  totalRecord: 0,
  loadingCreate: [],
  loadingUpdate: false,
  loadingActivate: false,
  loadingGetByIdData: false,
  getByIdData: {},
};

export const GetAllSSR = createAsyncThunk(
  "/ContactUsForm/GetAllSSR",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ContactUsService.GetAllSSR(data);

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetById = createAsyncThunk(
  "/ContactUsForm/GetById",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await ContactUsService.GetById({ id });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/ContactUsForm/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ContactUsService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const contactUsSlice = createSlice({
  name: "ContactUsForm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAllSSR.pending, (state) => {
        state.loadingSSR = true;
      })
      .addCase(GetAllSSR.fulfilled, (state, action) => {
        state.loadingSSR = false;
        state.ssrData = action.payload.data;
        state.totalRecord = action.payload.totalRecord;
      })
      .addCase(GetAllSSR.rejected, (state) => {
        state.loadingSSR = false;
        state.ssrData = [];
      })
      .addCase(GetById.pending, (state) => {
        state.loadingGetByIdData = true;
      })
      .addCase(GetById.fulfilled, (state, action) => {
        state.loadingGetByIdData = false;
        state.getByIdData = action.paylaod;
      })
      .addCase(GetById.rejected, (state) => {
        state.loadingGetByIdData = false;
      })

      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state) => {
        state.loadingActivate = false;
      });
  },
});

export const {} = contactUsSlice.actions;

export default contactUsSlice.reducer;
