import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";
const allRoles = ["SuperAdmin", "Admin"];

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "resetPassword",
    path: `${AUTH_PREFIX_PATH}/reset-password/:userId/:token`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/new-password")
    ),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "userList",
    path: `${APP_PREFIX_PATH}/User`,
    roles: allRoles.filter((e) => e !== "Admin"),
    component: React.lazy(() => import("views/app-views/General/User/List")),
  },
  {
    key: "addUser",
    path: `${APP_PREFIX_PATH}/User/Create`,
    roles: allRoles.filter((e) => e !== "Admin"),
    component: React.lazy(() => import("views/app-views/General/User/Add")),
  },
  {
    key: "editUser",
    path: `${APP_PREFIX_PATH}/User/Edit/:id`,
    roles: allRoles.filter((e) => e !== "Admin"),
    component: React.lazy(() => import("views/app-views/General/User/Edit")),
  },

  {
    key: "editProfile",
    path: `${APP_PREFIX_PATH}/Account/EditProfile`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/Account/EditProfile")
    ),
  },
  {
    key: "mailConfig",
    path: `${APP_PREFIX_PATH}/MailConfiguration`,
    roles: allRoles.filter((a) => a !== "Admin"),
    component: React.lazy(() => import("views/app-views/General/MailConfig")),
  },
  {
    key: "aboutUs",
    path: `${APP_PREFIX_PATH}/AboutUs`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/General/AboutUs")),
  },
  {
    key: "contactUsForm",
    path: `${APP_PREFIX_PATH}/ContactUsForm`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/ContactUsForm/List")
    ),
  },
  {
    key: "contactUsDetails",
    path: `${APP_PREFIX_PATH}/ContactUsForm/Details/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/ContactUsForm/Details/index")
    ),
  },
  {
    key: "terms&conditions",
    path: `${APP_PREFIX_PATH}/Terms&Conditions`,
    component: React.lazy(() =>
      import("views/app-views/General/TermsAndConditions")
    ),
  },
  {
    key: "privacy&policy",
    path: `${APP_PREFIX_PATH}/Privacy&Policy`,
    component: React.lazy(() =>
      import("views/app-views/General/PrivacyPolicy")
    ),
  },
  {
    key: "homeSeo",
    path: `${APP_PREFIX_PATH}/HomeSeo`,
    component: React.lazy(() => import("views/app-views/General/HomeSeo")),
  },
  {
    key: "banner",
    path: `${APP_PREFIX_PATH}/Banner`,
    roles: allRoles,
    component: React.lazy(() => import("views/app-views/General/Banner/List")),
  },
  {
    key: "addBanner",
    path: `${APP_PREFIX_PATH}/Banner/Create`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/Banner/Create")
    ),
  },
  {
    key: "editBanner",
    path: `${APP_PREFIX_PATH}/Banner/Edit/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/Banner/Update")
    ),
  },
  {
    key: "heroBanner",
    path: `${APP_PREFIX_PATH}/HeroBanner`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/HeroBanner/List")
    ),
  },
  {
    key: "addheroBanner",
    path: `${APP_PREFIX_PATH}/HeroBanner/Add`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/HeroBanner/Add")
    ),
  },
  {
    key: "categoryType",
    path: `${APP_PREFIX_PATH}/CategoryType`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/CategoryType/List")
    ),
  },
  // {
  //   key: "addCategoryType",
  //   path: `${APP_PREFIX_PATH}/CategoryType/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/Categories/CategoryType/Create")
  //   ),
  // },
  {
    key: "editCategoryType",
    path: `${APP_PREFIX_PATH}/CategoryType/Edit/:urlTitle/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/CategoryType/Update")
    ),
  },
  {
    key: "category",
    path: `${APP_PREFIX_PATH}/Category`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/Category/List")
    ),
  },
  {
    key: "addCategory",
    path: `${APP_PREFIX_PATH}/Category/Create`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/Category/Create")
    ),
  },
  {
    key: "editCategory",
    path: `${APP_PREFIX_PATH}/Category/Edit/:urlTitle/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/Category/Update")
    ),
  },

  {
    key: "subCategory",
    path: `${APP_PREFIX_PATH}/SubCategory`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/SubCategory/List")
    ),
  },
  {
    key: "addSubCategory",
    path: `${APP_PREFIX_PATH}/SubCategory/Create`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/SubCategory/Create")
    ),
  },
  {
    key: "editSubCategory",
    path: `${APP_PREFIX_PATH}/SubCategory/Edit/:urlTitle/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/SubCategory/Update")
    ),
  },

  {
    key: "product",
    path: `${APP_PREFIX_PATH}/Product`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/Product/List")
    ),
  },
  {
    key: "addProduct",
    path: `${APP_PREFIX_PATH}/Product/Create`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/Product/Create")
    ),
  },
  {
    key: "editProduct",
    path: `${APP_PREFIX_PATH}/Product/Edit/:urlTitle/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/Categories/Product/Update")
    ),
  },

  {
    key: "contactUs",
    path: `${APP_PREFIX_PATH}/ContactUs`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/ContactUs/List")
    ),
  },
  // {
  //   key: "addContactUs",
  //   path: `${APP_PREFIX_PATH}/ContactUs/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/General/ContactUs/Create")
  //   ),
  // },
  {
    key: "editContactUs",
    path: `${APP_PREFIX_PATH}/ContactUs/Edit/:urlTitle/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/ContactUs/Update")
    ),
  },

  {
    key: "aboutUsDetails",
    path: `${APP_PREFIX_PATH}/AboutUsDetails`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/AboutUsDetails/List")
    ),
  },
  {
    key: "addAboutUsDetails",
    path: `${APP_PREFIX_PATH}/AboutUsDetails/Create`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/AboutUsDetails/Create")
    ),
  },
  {
    key: "editAboutUsDetails",
    path: `${APP_PREFIX_PATH}/AboutUsDetails/Edit/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/AboutUsDetails/Update")
    ),
  },

  {
    key: "homePage",
    path: `${APP_PREFIX_PATH}/HomePage`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/HomePage/List")
    ),
  },
  // {
  //   key: "addHomePage",
  //   path: `${APP_PREFIX_PATH}/HomePage/Create`,
  //   component: React.lazy(() =>
  //     import("views/app-views/General/HomePage/Create")
  //   ),
  // },
  {
    key: "editHomePage",
    path: `${APP_PREFIX_PATH}/HomePage/Edit/:id`,
    roles: allRoles,
    component: React.lazy(() =>
      import("views/app-views/General/HomePage/Update")
    ),
  },
];
