import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ContactUsService from "services/ContactUsService";
import { message } from "antd";

export const initialState = {
  loadingAllData: false,
  loadingActivate: false,
  getAllData: [],
  createLoading: false,
  updateLoading: false,
  loadingGetByIdData: false,
  getByIdData: {},
};

export const Create = createAsyncThunk(
  "/ContactUs/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ContactUsService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/ContactUs/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await ContactUsService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/ContactUs/GetById",
  async ({ urlTitle }, { rejectWithValue }) => {
    try {
      const response = await ContactUsService.GetById({ urlTitle });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/ContactUs/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await ContactUsService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/ContactUs/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ContactUsService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const contactUsSlice = createSlice({
  name: "contactUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllData = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllData = false;
        state.getAllData = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllData = false;
        state.getAllData = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivate = false;
      });
  },
});

export const {} = contactUsSlice.actions;

export default contactUsSlice.reducer;
