import fetch from "auth/FetchInterceptor";

const ContactUsForm = {};

ContactUsForm.GetAllSSR = async function (data) {
  return fetch({
    url: "/ContactUsForm/GetAllSSR",
    method: "post",
    data,
  });
};
ContactUsForm.GetById = async function (params) {
  return fetch({
    url: "/ContactUsForm/GetById",
    method: "get",
    params,
  });
};

ContactUsForm.Activate = function (params) {
  return fetch({
    url: `/ContactUsForm/Activate`,
    method: "post",
    params,
  });
};

export default ContactUsForm;
