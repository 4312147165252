import fetch from "auth/FetchInterceptor";

const CategoryService = {};

CategoryService.Create = function (data) {
  return fetch({
    url: "/Category/Create",
    method: "post",
    data,
  });
};

CategoryService.Update = function (data) {
  return fetch({
    url: "/Category/Update",
    method: "post",
    data,
  });
};
CategoryService.Activate = function (params) {
  return fetch({
    url: "/Category/Activate",
    method: "post",
    params,
  });
};
CategoryService.GetAll = function () {
  return fetch({
    url: "/Category/GetAll",
    method: "get",
  });
};
CategoryService.GetCategoriesByCategoryType = function (params) {
  return fetch({
    url: "/Category/GetCategoriesByCategoryType",
    method: "get",
    params,
  });
};
CategoryService.ChangeOrder = function (data) {
  return fetch({
    url: "/Category/ChangeOrder",
    method: "post",
    data,
  });
};
CategoryService.GetById = function (params) {
  return fetch({
    url: "/Category/GetById",
    method: "get",
    params,
  });
};

export default CategoryService;
