import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categoryService from "services/CategoryService";
import { message } from "antd";

export const initialState = {
  loadingAllData: false,
  loadingActivate: false,
  getAllData: [],
  createLoading: false,
  updateLoading: false,
  loadingGetByIdData: false,
  getByIdData: {},
  loadingOrder: false,
  allCatByCatType: [],
  loadingAllCatByCatType: false,
  defaultValue: "",
};

export const Create = createAsyncThunk(
  "/Category/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await categoryService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/Category/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await categoryService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const ChangeOrder = createAsyncThunk(
  "/Category/ChangeOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await categoryService.ChangeOrder(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/Category/GetById",
  async ({ urlTitle }, { rejectWithValue }) => {
    try {
      const response = await categoryService.GetById({ urlTitle });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/Category/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await categoryService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const GetCategoriesByCategoryType = createAsyncThunk(
  "/Category/GetCategoriesByCategoryType",
  async ({ categoryTypeURL }, { rejectWithValue }) => {
    try {
      const response = await categoryService.GetCategoriesByCategoryType({
        categoryTypeURL,
      });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/Category/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await categoryService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllData = true;
        state.defaultValue = "";
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.defaultValue = action.payload[0].urlTitle;
        state.getAllData = action.payload;
        state.loadingAllData = false;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllData = false;
        state.defaultValue = "";
        state.getAllData = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(ChangeOrder.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(ChangeOrder.fulfilled, (state, action) => {
        state.loadingOrder = false;
      })
      .addCase(ChangeOrder.rejected, (state, action) => {
        state.loadingOrder = false;
      })
      .addCase(GetCategoriesByCategoryType.pending, (state) => {
        state.loadingAllCatByCatType = true;
      })

      .addCase(GetCategoriesByCategoryType.fulfilled, (state, action) => {
        state.loadingAllCatByCatType = false;
        state.allCatByCatType = action.payload.sort(
          (a, b) => a.orderNumber - b.orderNumber
        );
      })
      .addCase(GetCategoriesByCategoryType.rejected, (state, action) => {
        state.loadingAllCatByCatType = false;
        state.allCatByCatType = [];
      });
  },
});

export const {} = categorySlice.actions;

export default categorySlice.reducer;
