import fetch from "auth/FetchInterceptor";

const AboutUsService = {};

AboutUsService.GetAboutUsDetails = async function () {
  return fetch({
    url: "/AboutUs/GetAboutUs",
    method: "get",
  });
};

AboutUsService.UpdateAboutUs = async function (data) {
  return fetch({
    url: "/AboutUs/Update",
    method: "post",
    data,
  });
};

export default AboutUsService;
