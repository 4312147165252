import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import AboutUsService from "services/AboutUsService";

export const initialState = {
  loading: false,
  aboutUs: {},
  updateLoading: false,
};

export const GetAboutUs = createAsyncThunk(
  "/AboutUs/GetAboutUs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AboutUsService.GetAboutUsDetails();
      if (response.success == false) {
        return rejectWithValue(response.message);
      } else {
        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const UpdateAboutUs = createAsyncThunk(
  "/AboutUs/Update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AboutUsService.UpdateAboutUs(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response.result;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const aboutUsSlice = createSlice({
  name: "aboutUs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetAboutUs.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetAboutUs.fulfilled, (state, action) => {
        state.loading = false;
        state.aboutUs = action.payload;
      })
      .addCase(GetAboutUs.rejected, (state, action) => {
        state.aboutUs = {};
        state.loading = false;
      })
      .addCase(UpdateAboutUs.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(UpdateAboutUs.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(UpdateAboutUs.rejected, (state, action) => {
        state.updateLoading = false;
      });
  },
});

export const {} = aboutUsSlice.actions;

export default aboutUsSlice.reducer;
