import fetch from "auth/FetchInterceptor";

const TermsAndConditionsService = {};

TermsAndConditionsService.GetFirst = async function () {
  return fetch({
    url: "/TermsAndConditions/GetFirst",
    method: "get",
  });
};

TermsAndConditionsService.Update = async function (data) {
  return fetch({
    url: "/TermsAndConditions/Update",
    method: "post",
    data,
  });
};

export default TermsAndConditionsService;
