import {
  SIDE_NAV_LIGHT,
  NAV_TYPE_SIDE,
  DIR_LTR,
  DIR_RTL,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import { LANG_ID } from "constants/AuthConstant";

export const APP_NAME = "XCube";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/CategoryType`;
export const UNAUTHENTICATED_ENTRY = "/login";

const local = localStorage.getItem(LANG_ID) ?? "en";
export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: local,
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: local === "ar" ? DIR_RTL : DIR_LTR,
  blankLayout: false,
};
