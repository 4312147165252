import fetch from "auth/FetchInterceptor";

const HomePageService = {};

HomePageService.Create = function (data) {
  return fetch({
    url: "/HomePageNumber/Create",
    method: "post",
    data,
  });
};

HomePageService.Update = function (data) {
  return fetch({
    url: "/HomePageNumber/Update",
    method: "post",
    data,
  });
};
HomePageService.Activate = function (params) {
  return fetch({
    url: "/HomePageNumber/Activate",
    method: "post",
    params,
  });
};
HomePageService.GetAll = function () {
  return fetch({
    url: "/HomePageNumber/GetAll",
    method: "get",
  });
};
HomePageService.GetById = function (params) {
  return fetch({
    url: "/HomePageNumber/GetById",
    method: "get",
    params,
  });
};
HomePageService.ChangeOrder = function (data) {
  return fetch({
    url: "/HomePageNumber/ChangeOrder",
    method: "post",
    data,
  });
};

export default HomePageService;
