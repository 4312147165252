import fetch from "auth/FetchInterceptor";

const AboutUsDetailsService = {};

AboutUsDetailsService.Create = function (data) {
  return fetch({
    url: "/AboutUsDetails/Create",
    method: "post",
    data,
  });
};

AboutUsDetailsService.Update = function (data) {
  return fetch({
    url: "/AboutUsDetails/Update",
    method: "post",
    data,
  });
};
AboutUsDetailsService.Activate = function (params) {
  return fetch({
    url: "/AboutUsDetails/Activate",
    method: "post",
    params,
  });
};
AboutUsDetailsService.GetAll = function () {
  return fetch({
    url: "/AboutUsDetails/GetAll",
    method: "get",
  });
};
AboutUsDetailsService.GetById = function (params) {
  return fetch({
    url: "/AboutUsDetails/GetById",
    method: "get",
    params,
  });
};

export default AboutUsDetailsService;
