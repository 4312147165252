import fetch from "auth/FetchInterceptor";

const CategoryTypeService = {};

CategoryTypeService.Create = function (data) {
  return fetch({
    url: "/CategoryType/Create",
    method: "post",
    data,
  });
};

CategoryTypeService.Update = function (data) {
  return fetch({
    url: "/CategoryType/Update",
    method: "post",
    data,
  });
};
CategoryTypeService.Activate = function (params) {
  return fetch({
    url: "/CategoryType/Activate",
    method: "post",
    params,
  });
};
CategoryTypeService.GetAll = function () {
  return fetch({
    url: "/CategoryType/GetAll",
    method: "get",
  });
};
CategoryTypeService.GetById = function (params) {
  return fetch({
    url: "/CategoryType/GetById",
    method: "get",
    params,
  });
};
CategoryTypeService.ChangeOrder = function (data) {
  return fetch({
    url: "/CategoryType/ChangeOrder",
    method: "post",
    data,
  });
};

export default CategoryTypeService;
