import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subCategoryService from "services/SubCategoryService";
import { message } from "antd";

export const initialState = {
  loadingAllData: false,
  loadingActivate: false,
  getAllData: [],
  createLoading: false,
  updateLoading: false,
  loadingGetByIdData: false,
  getByIdData: {},
  loadingGetSubCatByCatType: false,
  getAllSubCatByCatType: [],
  loadingOrder: false,
  loadingSubCatToCat: false,
  allSubCatToCat: [],
};

export const Create = createAsyncThunk(
  "/SubCategory/Create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await subCategoryService.Create(data);
      if (response.success == false) {
        message.warning({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });

        return response;
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const Update = createAsyncThunk(
  "/SubCategory/Update",
  async (data, { rejectWithValue }) => {
    try {
      try {
        const response = await subCategoryService.Update(data);

        if (response.success == false) {
          message.warning({
            content: ` ${response.message}`,
          });
          return rejectWithValue(response.message);
        } else {
          message.success({
            content: ` ${response.message}`,
          });

          return response;
        }
      } catch (err) {
        return rejectWithValue(
          err.response?.data?.message || "Something Went Wrong !"
        );
      }
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetById = createAsyncThunk(
  "/SubCategory/GetById",
  async ({ urlTitle }, { rejectWithValue }) => {
    try {
      const response = await subCategoryService.GetById({ urlTitle });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetAll = createAsyncThunk(
  "/SubCategory/GetAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await subCategoryService.GetAll();

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetSubCategoriesByCategoryTypeId = createAsyncThunk(
  "/SubCategory/GetSubCategoriesByCategoryTypeId",
  async ({ categoryTypeURL }, { rejectWithValue }) => {
    try {
      const response =
        await subCategoryService.GetSubCategoriesByCategoryTypeId({
          categoryTypeURL,
        });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);
export const GetSubCategoriesByCategoryId = createAsyncThunk(
  "/SubCategory/GetSubCategoriesByCategoryId",
  async ({ categoryURL }, { rejectWithValue }) => {
    try {
      const response = await subCategoryService.GetSubCategoriesByCategoryId({
        categoryURL,
      });

      return response.result;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const Activate = createAsyncThunk(
  "/SubCategory/Activate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await subCategoryService.Activate(data);
      if (response.success == false) {
        message.error({
          content: ` ${response.message}`,
        });
        return rejectWithValue(response.message);
      } else {
        message.success({
          content: ` ${response.message}`,
        });
      }
      return response;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);
export const ChangeOrder = createAsyncThunk(
  "/SubCategory/ChangeOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await subCategoryService.ChangeOrder(data);
      return response;
    } catch (err) {
      return rejectWithValue(
        err.response?.data?.message || "Something Went Wrong !"
      );
    }
  }
);

export const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(Create.pending, (state) => {
        state.createLoading = true;
      })
      .addCase(Create.fulfilled, (state, action) => {
        state.createLoading = false;
      })
      .addCase(Create.rejected, (state, action) => {
        state.createLoading = false;
      })

      .addCase(Update.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(Update.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(Update.rejected, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(GetAll.pending, (state) => {
        state.loadingAllData = true;
      })

      .addCase(GetAll.fulfilled, (state, action) => {
        state.loadingAllData = false;
        state.getAllData = action.payload;
      })
      .addCase(GetAll.rejected, (state, action) => {
        state.loadingAllData = false;
        state.getAllData = [];
      })
      .addCase(Activate.pending, (state) => {
        state.loadingActivate = true;
      })
      .addCase(Activate.fulfilled, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(Activate.rejected, (state, action) => {
        state.loadingActivate = false;
      })
      .addCase(GetSubCategoriesByCategoryTypeId.pending, (state) => {
        state.loadingGetSubCatByCatType = true;
      })
      .addCase(GetSubCategoriesByCategoryTypeId.fulfilled, (state, action) => {
        state.loadingGetSubCatByCatType = false;
        state.getAllSubCatByCatType = action.payload;
      })
      .addCase(GetSubCategoriesByCategoryTypeId.rejected, (state, action) => {
        state.loadingGetSubCatByCatType = false;
        state.getAllSubCatByCatType = [];
      })
      .addCase(ChangeOrder.pending, (state) => {
        state.loadingOrder = true;
      })
      .addCase(ChangeOrder.fulfilled, (state, action) => {
        state.loadingOrder = false;
      })
      .addCase(ChangeOrder.rejected, (state, action) => {
        state.loadingOrder = false;
      })
      .addCase(GetSubCategoriesByCategoryId.pending, (state) => {
        state.loadingSubCatToCat = true;
      })
      .addCase(GetSubCategoriesByCategoryId.fulfilled, (state, action) => {
        state.loadingSubCatToCat = false;

        state.allSubCatToCat = action.payload.sort(
          (a, b) => a.orderNumber - b.orderNumber
        );
      })
      .addCase(GetSubCategoriesByCategoryId.rejected, (state, action) => {
        state.loadingSubCatToCat = false;
        state.allSubCatToCat = [];
      });
  },
});

export const {} = subCategorySlice.actions;

export default subCategorySlice.reducer;
