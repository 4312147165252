import fetch from "auth/FetchInterceptor";

const ProductService = {};

ProductService.Create = function (data) {
  return fetch({
    url: "/Product/Create",
    method: "post",
    data,
  });
};

ProductService.Update = function (data) {
  return fetch({
    url: "/Product/Update",
    method: "post",
    data,
  });
};
ProductService.Activate = function (params) {
  return fetch({
    url: "/Product/Activate",
    method: "post",
    params,
  });
};
ProductService.GetAll = function () {
  return fetch({
    url: "/Product/GetAll",
    method: "get",
  });
};
ProductService.GetById = function (params) {
  return fetch({
    url: "/Product/GetById",
    method: "get",
    params,
  });
};
ProductService.GetAllSSR = async function (data) {
  return fetch({
    url: "/Product/GetAllSSR",
    method: "post",
    data,
  });
};

export default ProductService;
