import fetch from "auth/FetchInterceptor";

const AccountService = {};

AccountService.UserProfile = async function (params) {
  return fetch({
    url: "/Account/GetById",
    method: "get",
    params,
  });
};
AccountService.GetRoles = async function () {
  return fetch({
    url: "/Account/GetRoles",
    method: "get",
  });
};
AccountService.UpdateProfile = async function (data) {
  return fetch({
    url: "/Account/Update",
    method: "post",
    data,
  });
};
AccountService.Activate = function (params) {
  return fetch({
    url: "/Account/Activate",
    method: "post",
    params,
  });
};

AccountService.ChangePassword = async function (data) {
  return fetch({
    url: "/Account/ChangePassword",
    method: "post",
    data,
  });
};
AccountService.GetAllSSR = async function (data) {
  return fetch({
    url: "/Account/GetAllSSR",
    method: "post",
    data,
  });
};
AccountService.Create = async function (data) {
  return fetch({
    url: "/Account/Create",
    method: "post",
    data,
  });
};
AccountService.Update = async function (data) {
  return fetch({
    url: "/Account/Update",
    method: "post",
    data,
  });
};

export default AccountService;
