import fetch from "auth/FetchInterceptor";

const SubCategoryService = {};

SubCategoryService.Create = function (data) {
  return fetch({
    url: "/SubCategory/Create",
    method: "post",
    data,
  });
};

SubCategoryService.Update = function (data) {
  return fetch({
    url: "/SubCategory/Update",
    method: "post",
    data,
  });
};
SubCategoryService.Activate = function (params) {
  return fetch({
    url: "/SubCategory/Activate",
    method: "post",
    params,
  });
};
SubCategoryService.GetAll = function () {
  return fetch({
    url: "/SubCategory/GetAll",
    method: "get",
  });
};
SubCategoryService.GetById = function (params) {
  return fetch({
    url: "/SubCategory/GetById",
    method: "get",
    params,
  });
};
SubCategoryService.ChangeOrder = function (data) {
  return fetch({
    url: "/SubCategory/ChangeOrder",
    method: "post",
    data,
  });
};
SubCategoryService.GetSubCategoriesByCategoryTypeId = function (params) {
  return fetch({
    url: "/SubCategory/GetSubCategoriesByCategoryTypeId",
    method: "get",
    params,
  });
};
SubCategoryService.GetSubCategoriesByCategoryId = function (params) {
  return fetch({
    url: "/SubCategory/GetSubCategoriesByCategoryId",
    method: "get",
    params,
  });
};

export default SubCategoryService;
