import fetch from "auth/FetchInterceptor";

const ContactUsService = {};

ContactUsService.Create = function (data) {
  return fetch({
    url: "/ContactUs/Create",
    method: "post",
    data,
  });
};

ContactUsService.Update = function (data) {
  return fetch({
    url: "/ContactUs/Update",
    method: "post",
    data,
  });
};
ContactUsService.Activate = function (params) {
  return fetch({
    url: "/ContactUs/Activate",
    method: "post",
    params,
  });
};
ContactUsService.GetAll = function () {
  return fetch({
    url: "/ContactUs/GetAll",
    method: "get",
  });
};
ContactUsService.GetById = function (params) {
  return fetch({
    url: "/ContactUs/GetById",
    method: "get",
    params,
  });
};

export default ContactUsService;
