import fetch from "auth/FetchInterceptor";

const PrivacyPolicyService = {};

PrivacyPolicyService.GetFirst = async function () {
  return fetch({
    url: "/PrivacyPolicy/GetFirst",
    method: "get",
  });
};

PrivacyPolicyService.Update = async function (data) {
  return fetch({
    url: "/PrivacyPolicy/Update",
    method: "post",
    data,
  });
};

export default PrivacyPolicyService;
