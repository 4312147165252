import fetch from "auth/FetchInterceptor";

const HomeSeoService = {};

HomeSeoService.Get = async function () {
  return fetch({
    url: "/HomeSEO/Get",
    method: "get",
  });
};

HomeSeoService.Update = async function (data) {
  return fetch({
    url: "/HomeSEO/Update",
    method: "post",
    data,
  });
};

export default HomeSeoService;
